import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import PageTemplate from '../components/PageTemplate'

import mozaic_dsktp from '../images/awards/awards_mozaic_dsktp.png'
import mozaic_mbl from '../images/awards/awards_mozaic_mbl.png'
import LionHealthLogo from '../images/awards/awards_logo_lion_dsktp.png'
import BestPlacesLogo from '../images/awards/awards_logo_placetowork_dsktp.png'

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #001b33;
  }
`

const PageContent = styled.div`
    position: relative;
    z-index: 1;
    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        padding-top: 135px;
    }
`
const PageContentInner = styled.div`
    padding-left: ${(props) => props.theme.paddingSidesMobile};
    padding-right: ${(props) => props.theme.paddingSidesMobile};
    padding-top: 100px;
    padding-bottom: 40px;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
`
const CopyContainer = styled.div`
    width: 100%;
    margin-bottom: 23%;

    h1 {
        color: ${(props) => props.theme.blueDark};
        font-weight: 900;
        font-size: 2.1875rem;
    }

    p {
        color: ${(props) => props.theme.blueDark};
        line-height: 1.75;
        padding-right: 35px;

        span {
            font-weight: 900;
            display: block;
            margin-top: 1rem;
            width: 60%;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        margin-bottom: 45px;
        padding-left: ${(props) => props.theme.paddingSidesDesktop};

        p {
            font-size: 1.1rem;
            margin-bottom: 0;
            width: 100%;

            span {
                display: inline;
                margin-top: 0;
                width: auto;
            }
        }

        .bannerHeader {
            width: 70%;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointDesktop}) {
        h1 {
            font-size: 2.875rem;
        }
    }

    @media (max-width: 440px) {
        margin-bottom: 10%;
    }
`

const BackgroundContent = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #cddbe7, #fff);

    border-bottom-width: 2px;
    border-bottom-color: white;
    border-bottom-style: solid;
    -webkit-box-shadow: 0 0 30px 10px rgba(151, 200, 230, 1);
    box-shadow: 0 0 30px 10px rgba(151, 200, 230, 1);

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
    }
`
const AwardsRow = styled.div`
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e5f1f9+0,bfdff2+100 */
    background: #e5f1f9; /* Old browsers */
    background: -moz-linear-gradient(left, #e5f1f9 0%, #bfdff2 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #e5f1f9 0%, #bfdff2 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #e5f1f9 0%, #bfdff2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5f1f9', endColorstr='#bfdff2',GradientType=1 ); /* IE6-9 */

    position: relative;
    display: block;
    margin-bottom: 1rem;

    left: -${(props) => props.theme.paddingSidesMobile};
    width: calc(100% + ${(props) => props.theme.paddingSidesMobile} + ${(props) => props.theme.paddingSidesMobile});

    &:first-child:after {
        content: '';
        display: none;
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: top right;
        background-size: auto 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        display: flex;
        margin-bottom: 1.75rem;
        width: auto;
        left: 0;
    }
`
const AwardType = styled.div`
    width: 100%;
    height: 100px;
    background-repeat: no-repeat;
    background-position: 30px;
    background-size: 65% auto;

    &.lion {
        background-image: url(${LionHealthLogo});
        background-size: 50% auto;
    }

    &.bestplaces {
        background-image: url(${BestPlacesLogo});
        background-position: 90% 30px;
        background-size: 35% auto;
    }

    h2 {
        position: relative;
        font-weight: 900;
        color: ${(props) => props.theme.blueDark};
        text-align: left;
        font-size: 2.2rem;
        padding-top: 1.25rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 90%;
            height: 5px;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#78bde8+0,0179c1+100 */
            background: #78bde8; /* Old browsers */
            background: -moz-linear-gradient(left, #78bde8 0%, #0179c1 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #78bde8 0%, #0179c1 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                to right,
                #78bde8 0%,
                #0179c1 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#78bde8', endColorstr='#0179c1',GradientType=1 ); /* IE6-9 */
        }
    }

    @media (min-width: 400px) {
        &.lion {
            background-size: 200px auto;
        }
        &.bestplaces {
            background-size: auto 150px;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        width: 27%;
        height: auto;

        &.lion {
            background-position: right 30px;
            background-size: 65% auto;
        }

        &.bestplaces {
            background-position: right 30px;
            background-size: 65% auto;
        }

        h2 {
            text-align: right;
            padding-left: 0;

            &:after {
                right: 0;
                left: auto;
                width: 80%;
            }
        }
    }
`
const AwardsList = styled.ul`
    padding: 0 1.25rem 1.25rem 1.25rem;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        width: 73%;
        padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    }
`
const AwardListItem = styled.li`
    display: block;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        display: flex;
        align-items: flex-start;
    }
`
const AwardYear = styled.h6`
    color: ${(props) => props.theme.blueDark};
    font-weight: 300;
    font-size: 2.75rem;
    line-height: 1;
    margin: 0;
    width: 14rem;

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        text-align: right;
    }
`
const AwardRecipients = styled.ul`
    list-style-type: none;
    padding-left: 0;

    li {
        color: ${(props) => props.theme.blueDark};
        margin-bottom: 0.4rem;

        span {
            font-weight: 900;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        padding-left: 1.3rem;
    }
`

class IndexPage extends React.Component {
    state = {}

    componentDidMount = () => {}

    render() {
        return (
            <PageTemplate metaDataIndex={4} selectedNavIndex={4}>
                <GlobalStyles />
                <PageContent>
                    <BackgroundContent></BackgroundContent>
                    <PageContentInner role="main" id="main">
                        <CopyContainer>
                            <h1>The Difference Is in Our Work</h1>
                            <p>
                                We believe the best places to work create the best work. Ideas that challenge current thinking and
                                make a real difference for our clients' businesses—and for which our agencies gather honors, year
                                after year. <span>Here are some recent awards that set us apart.</span>
                            </p>
                        </CopyContainer>

                        <div>
                            <AwardsRow>
                                <AwardType className={`withLogo lion`}></AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2024</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Health & Wellness Grand Prix—<span>FCB Chicago</span>
                                            </li>
                                            <li>
                                                Pharma Grand Prix—<span>Area 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Radio & Audio Grand Prix—<span>Golin London</span>
                                            </li>
                                            <li>
                                                PR Grand Prix—<span>Golin London</span>
                                            </li>
                                            <li>
                                                Healthcare Network of the Year—<span>FCB Health</span>
                                            </li>
                                            <li>
                                                Healthcare Agency of the Year—<span>Area 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>

                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Entertainment for Sport Grand Prix—<span>FCB New York</span>
                                            </li>
                                            <li>
                                                North America Network of the Year—<span>FCB</span>
                                            </li>
                                            <li>
                                                Health Network of the Year—<span>FCB Health</span>
                                            </li>
                                            <li>
                                                Health Agency of the Year—<span>Area 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Corporate Purpose & Social Responsibility Direct Grand Prix—
                                                <span>FCB Toronto</span>
                                            </li>
                                            <li>
                                                Direct Grand Prix—<span>Current Global Chicago with Weber Shandwick</span>
                                            </li>
                                            <li>
                                                Global Strategy Agency of the Year—<span>FCB Toronto</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>

                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Health Network of the Year—<span>FCB Health</span>
                                            </li>
                                            <li>
                                                Health Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Health Grand Prix for Good—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>

                                    <AwardListItem>
                                        <AwardYear>2020/2021</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Health Network of the Year—<span>FCB Health</span>
                                            </li>
                                            <li>
                                                Health Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Pharma Grand Prix—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Radio &#38; Audio Grand Prix—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                PR Grand Prix—
                                                <span>McCann Paris with McCann Health London and Weber Shandwick</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>

                                    <AwardListItem>
                                        <AwardYear>2019</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Health and Wellness Grand Prix—<span>McCann Tel Aviv</span>
                                            </li>
                                            <li>
                                                Pharma Grand Prix—<span>McCann Health Shanghai</span>
                                            </li>
                                            <li>
                                                Innovation Grand Prix—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Health Network of the Year—<span>McCann Health</span>
                                            </li>
                                            <li>
                                                Health Agency of the Year—<span>McCann Health Shanghai</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2018</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Health Network of the Year—<span>FCB Health</span>
                                            </li>
                                            <li>
                                                Lead Idea Agency—<span>Weber Shandwick</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2017</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Health Network of the Year—<span>McCann Health (2016 and 2017)</span>
                                            </li>
                                            <li>
                                                Health Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Grand Prix for Good—<span>McCann Health</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>Clio Health</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Design (Pharmaceutical) GRAND HEALTH CLIO—
                                                <span>Area 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Creative Use of Data (Pharmaceutical) GRAND HEALTH CLIO—
                                                <span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Network of the Year—<span>IPG Health</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Network of the Year—<span>IPG Health</span>
                                            </li>
                                            <li>
                                                Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withLogo bestplaces`}></AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Forbes "Best Employers for Diversity"—<span>Top 7</span>
                                            </li>
                                            <li>
                                                Forbes "Best Employers for Diversity (Media and Advertising)"—<span>#1</span>
                                            </li>
                                            <li>TIME's "World's Best Companies"</li>
                                            <li>Forbes "America's Best Large Employers"</li>
                                            <li>
                                                FTSE4Good Index—<span>5th Consecutive Year</span>
                                            </li>
                                            <li>Disability Equality Index’s “Best Places to Work for Disability Inclusion”</li>
                                            <li>USA Today’s “America’s Climate Leaders"</li>
                                            <li>
                                                Bloomberg Gender-Equality Index (GEI)—<span>3rd Consecutive Year</span>
                                            </li>
                                            <li>
                                                Dow Jones Sustainability Index (North America)—<span>4th Consecutive Year</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>Newsweek's "America's Most Responsible Companies"</li>
                                            <li>Forbes' America's Best Employers for Diversity—Top 5</li>
                                            <li>Bloomberg Gender-Equality Index</li>
                                            <li>Human Rights Campaign (HRC) 100% Corporate Equality Index (13th Year)</li>
                                            <li>
                                                Ad Age's Best Places to Work—<span>FCB Health New York</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2021</AwardYear>
                                        <AwardRecipients>
                                            <li>Forbes' America's Best Employers for Diversity—Top 10</li>
                                            <li>Bloomberg Gender-Equality Index</li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2020</AwardYear>
                                        <AwardRecipients>
                                            <li>Bloomberg Gender-Equality Index</li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2019</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                LinkedIn's Top Companies List in Marketing and Advertising—
                                                <span>Interpublic Group</span>
                                            </li>
                                            <li>
                                                Ad Age's Best Places to Work—<span>McCann Health, Weber Shandwick</span>
                                            </li>
                                            <li>
                                                Forbes' America's Best Employers for Diversity—<span>IPG Mediabrands</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    {/* <AwardListItem>
                    <AwardYear>2018</AwardYear>
                    <AwardRecipients>
                      <li>13th Best Medium Workplace—<span>Virgo Health</span></li>
                      <li>HRC Best Places to Work for LGBTQ Equality—<span>Interpublic Group</span></li>
                    </AwardRecipients>
                  </AwardListItem> */}
                                    {/* <AwardListItem>
                    <AwardYear>2017</AwardYear>
                    <AwardRecipients>
                      <li>13th Best Medium Workplace—<span>Virgo Health</span></li>
                    </AwardRecipients>
                  </AwardListItem> */}
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>Adweek</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2024</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                US Media Agency of the Year—<span>Mediahub</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Global Media Agency of the Year—<span>Initiative</span>
                                            </li>
                                            <li>
                                                Experiential Agency of the Year—<span>Jack Morton</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2020</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Global Agency of the Year—<span>FCB</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>Advertising Age</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2024</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Healthcare Network of the Year—<span>IPG Health</span>
                                            </li>
                                            <li>
                                                US Network of the Year—<span>IPG Mediabrands</span>
                                            </li>
                                            <li>
                                                Media Agency of the Year—<span>UM</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Agency of the Year—<span>The Martin Agency</span>
                                            </li>
                                            <li>
                                                Healthcare Network of the Year—<span>IPG Health</span>
                                            </li>
                                            <li>
                                                Media Agency of the Year—<span>Initiative</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Multicultural Agency of the Year—<span>IW Group</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>Fast Company</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2024</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Most Innovative Companies in Advertising and Marketing—<span>Deutsch LA</span>
                                            </li>
                                            <li>
                                                Most Innovative Companies in Advertising and Marketing—
                                                <span>McCann Worldgroup</span>
                                            </li>
                                            <li>
                                                Most Innovative Companies in Advertising and Marketing—
                                                <span>The Martin Agency</span>
                                            </li>
                                            <li>
                                                Most Innovative Companies in Public Relations and Brand Strategies—
                                                <span>Weber Shandwick</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Most Innovative Companies in Advertising—<span>The Martin Agency</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Most Innovative Advertising Agencies—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>MM+M Awards</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Large Healthcare Network of the Year—<span>IPG Health</span>
                                            </li>
                                            <li>
                                                Large Healthcare Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2021</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Best In Show / Titanium Award—<span>McCann Health</span>
                                            </li>
                                            <li>
                                                Medical Communications Agency—<span>Gold (Trio), Silver (AREA 23 on Hudson)</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>MANNY Awards</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Agency of the Year—<span>Area 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>Area 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Creative Agency—<span>Area 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Network of the Year—<span>IPG Health</span>
                                            </li>
                                            <li>
                                                Vision Award—<span>IPG Health</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Network of the Year—<span>IPG Health</span>
                                            </li>
                                            <li>
                                                Advertising Agency of the Year—
                                                <span>FCB Health New York, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Creative Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2021</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Network of the Year—<span>FCB Health Network</span>
                                            </li>
                                            <li>
                                                Advertising Agency of the Year—
                                                <span>FCB Health New York, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Creative Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2020</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Creative Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Agency of the Year Category III—<span>McCann Health Managed Markets</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2019</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Network of the Year—<span>FCB Health Network</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2018</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Agency of the Year—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Creative Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2017</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Industry Person of the Year, Dana Maiman—<span>FCB Health</span>
                                            </li>
                                            <li>
                                                Agency of the Year, Category I—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Admired Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                            <li>
                                                Most Creative Agency—<span>AREA 23, an IPG Health Company</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>Effie Awards</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                US Most Effective Holding Company—<span>Interpublic Group</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2022</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                US Most Effective Holding Company—<span>Interpublic Group</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2020</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                US Most Effective Holding Company—<span>Interpublic Group</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>

                            <AwardsRow>
                                <AwardType className={`withName`}>
                                    <h2>Sabre Awards</h2>
                                </AwardType>
                                <AwardsList>
                                    <AwardListItem>
                                        <AwardYear>2023</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Global Agency of the Year—<span>Weber Shandwick</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2021</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                North America PR Agency of the Year—<span>Golin</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                    <AwardListItem>
                                        <AwardYear>2020</AwardYear>
                                        <AwardRecipients>
                                            <li>
                                                Global Agency of the Decade—<span>Weber Shandwick</span>
                                            </li>
                                        </AwardRecipients>
                                    </AwardListItem>
                                </AwardsList>
                            </AwardsRow>
                        </div>
                    </PageContentInner>
                </PageContent>
            </PageTemplate>
        )
    }
}

export default IndexPage
